<template>
  <div>
    <div id="custom_card" class="card sticky">
      <div class="grid justify-content-between">
        <div class="col-12 md:col-6">
          <div
            class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
          >
            <p style="color: #6b719b; font-size: 20px; font-weight: 800">
              Dashboard
            </p>
          </div>
        </div>
      </div>
    </div>
    <loading
      :active="isLoadingModel"
      :can-cancel="true"
      color="#274461"
      :is-full-page="fullPage"
    ></loading>
    <div class="grid">

      <div class="col-12 lg:col-4 xl:col-4 zoom">
          <div class="card mb-0" id="custom_card_shadow" @click="go('/tickets')">
            <div class="flex justify-content-between mb-0">
              <div>
                <span class="block text-500 font-medium mb-3">Open Tickets</span>
                <div class="text-5xl" style="color: #6b719b; font-size: 20px; font-weight: 800">{{ open_tickets }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-blue-100 border-round"
                style="width: 6rem; height: 5rem"
              >
                <i class="pi pi-shopping-cart text-blue-500 text-5xl"></i>
              </div>
            </div>
          
          </div>
        </div>
        <div class="col-12 lg:col-4 xl:col-4 zoom">
          <div class="card mb-0" id="custom_card_shadow" @click="go('/tickets')">
            <div class="flex justify-content-between mb-0">
              <div>
                <span class="block text-500 font-medium mb-3">Closed Tickets</span>
                <div class="text-5xl" style="color: #6b719b; font-size: 20px; font-weight: 800">{{ closed_tickets }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-orange-100 border-round"
                style="width: 6rem; height: 5rem"
              >
                <i class="pi pi-inbox text-orange-500 text-5xl"></i>
              </div>
            </div>
          
          </div>
        </div>
        <div class="col-12 lg:col-4 xl:col-4 zoom">
          <div class="card mb-0" id="custom_card_shadow" @click="go('/tickets')"> 
            <div class="flex justify-content-between mb-0">
              <div>
                <span class="block text-500 font-medium mb-3">Inprogress Tickets</span>
                <div class="text-5xl" style="color: #6b719b; font-size: 20px; font-weight: 800">{{ inprogress_tickets }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-purple-100 border-round"
                style="width: 6rem; height: 5rem"
              >
                <i class="pi pi-comment text-purple-500 text-5xl"></i>
              </div>
            </div>
          
          </div>
        </div>


      <!-- <div class="col-12 lg:col-6 xl:col-6 zoom">
        <div v-on:click="go_to_user_rider()">
          <div
            class="card mb-0"
            style="background-color: #a3cc88"
            id="custom_card_shadow"
          >
            <div class="flex justify-content-between mb-1">
              <div>
                <h4
                  class="mb-3"
                  style="color: black; font-size: 20px; font-weight: 700"
                >
                  Open Tickets
                </h4>
                <div class="text-900 font-medium text-xl">
                  <b>{{ open_tickets }}</b>
                </div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-blue-100 border-round"
                style="width: 4rem; height: 4rem"
              >
                <i class="pi pi-fw pi-bookmark text-blue-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-6 zoom">
        <div v-on:click="go_to_driver()">
          <div
            class="card mb-0"
            style="background-color: #f8c100"
            id="custom_card_shadow"
          >
            <div class="flex justify-content-between mb-1">
              <div>
                <h4
                  class="mb-3"
                  style="color: black; font-size: 20px; font-weight: 700"
                >
                  Closed Tickets
                </h4>
                <div class="text-900 font-medium text-xl">
                  <b>{{ closed_tickets }}</b>
                </div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-orange-100 border-round"
                style="width: 4rem; height: 4rem"
              >
                <i class="pi pi-fw pi-times text-orange-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
  <script>
import EventBus from "@/AppEventBus";
import ProductService from "../service/ProductService";
import apis from "@/apis";
import axios from "axios";
import { Tabs, Tab, TabPanels, TabPanel } from "vue3-tabs";
import superset_dashboard from "../components/superset_dashboard";
import DashboardComponent from "../components/DashboardComponent";
export default {
  data() {
    return {
      tabs: ["Dashboard"],
      selectedTab: "Dashboard",
      open_tickets: 0,
      closed_tickets: 0,
      inprogress_tickets: 0,
      isLoadingModel: false,
      fullPage: false,
    };
  },
  productService: null,
  themeChangeListener: null,
  async mounted() {},
  async created() {
    window.Buffer = window.Buffer || Buffer;
    this.productService = new ProductService();
    this.user_key = localStorage.getItem("user_key");
    this.DashboardData();
  },
  watch: {
    selectedTab() {
      if (this.selectedTab == "Dashboard") {
        this.DashboardData();
      }
    },
  },
  methods: {
    go(path)
        {
            this.$router.push(path);
            
        },
    async DashboardData() {
      var data = {
        customer_id: localStorage.getItem('id'),
        agent_id: "",
      };
      this.isLoadingModel = true;
      var promise = apis.customerDashboardData(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        var temp = response.data.data;
        for (let index = 0; index < temp.length; index++) {
          const element = temp[index];
          if (element.name == "Open") {
            this.open_tickets = element.total;
          }
          if (element.name == "In Progress") {
            this.inprogress_tickets = element.total;
          }
          if (element.name == "Closed") {
            this.closed_tickets = element.total;
          }
        }
      });
    },
  },
  components: {
    Tabs,
    Tab,
    TabPanels,
    superset_dashboard,
    DashboardComponent,
  },
};
</script>
  <style>
</style>
  <style scoped>
#mapContainer {
  width: 100%;
  height: 100vh;
}
.p-button-sm {
  font-size: 0.7rem;
  padding: 0.4rem 0.8rem;
}
.active {
  border-bottom: 4px solid #274461;
  color: black;
  font-weight: bold;
}
.tab:hover {
  font-weight: bold;
  border-bottom: 4px solid #274461;
}
.inactive {
  color: black;
}
.card {
  cursor: pointer;
}
#custom_card {
  border-top: 3px solid #6b719b;
}
.zoom {
  transform-origin: 0% 0% 1% 1%;
  transition: transform 1s, filter 1s ease-in-out;
}
/* The Transformation */
.zoom:hover {
  transform: scale(1.1);
}
#custom_card_shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}
</style>